import config from '../../config'
import { buildGetQuery } from '../../util'

import { INotificationType, IGetRequestObject } from '../../interfaces'
type Params = {
  airportId: number
}

const getNotificationTypesRequest = (params: Params): IGetRequestObject => {
  const { airportId } = params

  return {
    method: 'get',
    url: `${config.baseUrl}/${airportId}/Notifications/Types`,
  }
}

const useNotificationTypesQuery = buildGetQuery<Params, undefined, INotificationType[] | undefined>({
  getRequest: getNotificationTypesRequest,
  getQueryKey: (_) => ['notification-types'],
})

export default useNotificationTypesQuery
